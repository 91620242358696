import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getDataOfPage,
  isDataValid,
} from 'src/components/Dashboard/utils/dataHelpers';
import { browserHistory } from 'src/common/utils/browserHistory';
import _ from 'lodash';
import { fetchExternalScreenings } from 'src/actions/Screening/Group';
import ExternalScreeningsTable from 'src/components/Dashboard/components/Screenings/ExternalScreeningsTable';
import FilterBar from 'src/components/Dashboard/components/FilterBar';

function ExternalScreenings(props) {
  const { screenings, isFetching } = props;

  const [page, setPage] = useState(1);
  const [{ sortBy, sortDirection }, setSort] = useState({
    sortBy: 'updated_at',
    sortDirection: 'desc',
  });

  async function fetch(
    newPage = screenings.currentPage || 1,
    filterOptions = props.filters,
    newSortBy = sortBy,
    newSortDirection = sortDirection,
  ) {
    props.fetchExternalScreenings(
      {
        filters: filterOptions,
        page: newPage,
        sortBy: newSortBy,
        sortDirection: newSortDirection,
      },
      props.templates,
    );
  }

  const shouldFetch = (currentPage) => {
    if (isDataValid(screenings, currentPage)) {
      setPage(currentPage);
    } else {
      fetch(currentPage, props.filters, sortBy, sortDirection);
    }
  };

  useEffect(() => {
    shouldFetch(screenings.currentPage || page);
  }, []);

  useEffect(() => {
    setPage(screenings.currentPage);
  }, [screenings.currentPage]);

  const onCellClick = (screen) => {
    if (screen) {
      const screenId = screen.id;
      const clientId = screen.subject.id;
      browserHistory.push(
        `/screenings/v2/facesheet/${clientId}/submission/${screenId}`,
      );
    }
  };

  const onFiltersChange = (key, newFilters) => {
    const updatedFilters = props.filters.map((filter) => {
      if (filter.key === key) {
        return {
          ...filter,
          options: filter.options.map((option) => ({
            ...option,
            initial: newFilters.includes(option.value),
          })),
        };
      }
      return filter;
    });

    fetch(1, updatedFilters);
    setPage(1);
  };

  const onNextClick = () => {
    shouldFetch(page + 1);
  };

  const onPrevClick = () => {
    shouldFetch(page - 1);
  };

  const setSorting = (sort) => {
    fetch(1, props.filters, sort.sortBy, sort.sortDirection);
    setSort(sort);
    setPage(1);
  };

  const pagedData = getDataOfPage(screenings, page, false);

  const { filters } = props;

  return (
    <div>
      <FilterBar
        filters={_.sortBy(filters, 'name')}
        onFiltersChange={onFiltersChange}
        paging={pagedData.paging}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
        isFetching={isFetching && !_.isEmpty(screenings)}
      />
      <ExternalScreeningsTable
        screenings={pagedData.data}
        isFetching={isFetching}
        sortBy={sortBy}
        sortDirection={sortDirection}
        setSort={setSorting}
        paging={pagedData.paging}
        onCellClick={onCellClick}
      />
    </div>
  );
}

ExternalScreenings.propTypes = {
  fetchExternalScreenings: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  screenings: PropTypes.shape({
    data: PropTypes.array.isRequired,
    currentPage: PropTypes.number.isRequired,
  }).isRequired,
  templates: PropTypes.array.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

ExternalScreenings.defaultProps = {
};

function mapStateToProps(state) {
  return {
    screenings: _.get(state, 'dashboard.screenings', {}),
    filters: _.get(state, 'dashboard.screenings.filters', []),
    templates: _.get(state, 'dashboard.screeningsExtraData.templates', []),
    isFetching: _.get(state, 'dashboard.screenings.isFetching', false),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators({ fetchExternalScreenings }, dispatch),
    dispatch,
  };
}

ExternalScreenings.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalScreenings);
