import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Spinner } from 'common/spinners';
import { Serializer } from '@unite-us/client-utils';
import { Button } from '@unite-us/ui';
import callOrLog from 'common/utils/callOrLog';
import { REFERRED_OUT_OF_NETWORK } from 'src/components/Referrals/constants';
import { REFERRAL } from 'common/utils/EventTracker/utils/eventConstants';
import {
  ReferralGroupHeader,
  ReferralGroupSelect,
  ReferralProgramSelect,
  ReferralProgramGroupSelect,
} from './components';
import {
  canAddMoreGroups,
  pluckSelectedGroupIds,
  pluckSelectedProgramIds,
} from './utils';
import { removeSelectedGroupFields } from './utils/removeGroupFields';
import { deselected, singleFieldRow } from '../ReferralFormFields/utils/selectField';
import './stylesheets/referral-groups-programs.scss';
import { getInvalidGroupErrors, getInvalidSensitiveErrors } from
  '../ReferralFormFields/MimicReferralGroupValidations/utils';
import AddProgramsSection from '../../Browse/AddProgramsSection/AddProgramsSection';

class ReferralGroupsPrograms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disableOrgAddButton: false,
      showOrgAddButton: false,
      hasSensitiveError: false,
    };

    this.onGroupSelect = this.onGroupSelect.bind(this);
    this.onProgramSelect = this.onProgramSelect.bind(this);
    this.onProgramGroupSelect = this.onProgramGroupSelect.bind(this);
    this.addGroupField = this.addGroupField.bind(this);
    this.checkSensitiveErrors = this.checkSensitiveErrors.bind(this);
    this.throwSensitiveError = this.throwSensitiveError.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.onDeselect = this.onDeselect.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { selectedFields, suggestedGroups } = nextProps;
    const showOrgAddButton = canAddMoreGroups({ selectedFields, suggestedGroups });

    if (this.state.showOrgAddButton !== showOrgAddButton) {
      this.setState({ showOrgAddButton });
    }

    const selectedGroups = _.compact(selectedFields);
    this.setState({
      disableOrgAddButton: _.some(selectedGroups, ['group.value', '']),
    });

    if (nextProps.selectedFields.length === 0 && nextProps.suggestedGroups.length > 0) {
      nextProps.selectedFields.addField();
    }
    if (nextProps.selectedFields.length > 1 && !_.get(nextProps, 'selectedFields[0].group.value')) {
      const values = nextProps.selectedFields.map(({ group } = {}) => group.value);
      for (let i = values.length - 1; i > 0; i--) {
        if (values[i] === '' && nextProps.selectedFields[i]) {
          nextProps.selectedFields.removeField(i);
        }
      }
    }

    this.checkSensitiveErrors();
  }

  onProgramSelect(program, index) {
    const { feeScheduleProgramId, setFeeScheduleProgramId } = this.props;

    if (deselected(program)) {
      if (!_.isNull(feeScheduleProgramId)) {
        this.removeSelectedFieldsExceptIndex(index);
        setFeeScheduleProgramId(null);
      }
    } else {
      const feeScheduleProgram = _.get(program, 'relationships.fee_schedule_program.data', {});
      if (feeScheduleProgram?.authorization_required && _.isNull(feeScheduleProgramId)) {
        this.removeSelectedFieldsExceptIndex(index);
        setFeeScheduleProgramId(feeScheduleProgram.id);
      }
    }
  }

  onProgramGroupSelect(selected, index) {
    const {
      selectedProgramsContext: {
        dispatchRemoveProgram,
        dispatchRemoveAllPrograms,
        dispatchAddProgram,
        selectedPrograms,
      },
    } = this.props;
    if (deselected(selected)) {
      // group.value.programs contains only the one program we want to remove
      const programId = this.props.selectedFields[index].group.value.programs[0]?.id;
      if (selectedPrograms.some((p) => p.id === programId)) dispatchRemoveProgram({ id: programId });
      this.onDeselect(index);
    } else {
      const group = _.get(selected, 'relationships.provider.data', '');
      dispatchRemoveAllPrograms();
      this.props.selectedFields.forEach((field) => {
        const program = field?.program?.value;
        if (program?.id && program?.attributes?.provider?.id) {
          dispatchAddProgram({
            id: program?.id,
            name: program?.name,
            providerId: program?.attributes?.provider?.id,
          });
        }
      });
      this.onSelect(group);
    }
    this.checkSensitiveErrors();
  }

  onGroupSelect(selected, index) {
    const { selectedFields } = this.props;

    if (selected && !_.isEmpty(selectedFields[index].program.value)) { // clear selected group's program
      selectedFields[index].program.onChange('');
    }

    if (deselected(selected)) {
      this.onDeselect(index);
    }

    if (selected) {
      this.onSelect(selected);
    }

    this.checkSensitiveErrors();
  }

  onSelect(selected) {
    const { canPaginateNetworkGroups, contact } = this.props;
    if (canPaginateNetworkGroups) {
      this.props.removeSelectedBrowseGroup('in-network');
    }

    // See hint-421, 422, and this Slack thread: https://uniteus.slack.com/archives/C03FKCZNYAV/p1687980924001949
    if (this.props.isProgramBasedSearch) {
      return;
    }
    const { id, name } = this.props.network;
    const eventPayload = Serializer.build({ contact });
    const slimGroup = Serializer.build({ dropDownGroupDetails: { ...selected, network: { id, name } } });
    callOrLog(() => this.context.eventTracker(REFERRAL.referralGroupSelected, {
      contact: eventPayload,
      ...slimGroup,
    }));
  }

  onDeselect(index) {
    const {
      canPaginateNetworkGroups,
      feeScheduleProgramId,
      selectedFields,
      setFeeScheduleProgramId,
    } = this.props;

    if (canPaginateNetworkGroups) {
      this.props.removeSelectedBrowseGroup('in-network');
    }

    if (singleFieldRow(selectedFields.length)) {
      selectedFields[index].program.onChange('');
      selectedFields[index].group.onChange('');
      if (!_.isNull(feeScheduleProgramId)) {
        setFeeScheduleProgramId(null);
      }
    } else {
      // If conditions: auth-required flow is activated, there're 2 or more fields
      // User is trying to remove the first provider, second provider does NOT
      // have a program selected --> result: auth-required flow deactivated & reload provider options
      if (
        !_.isNull(feeScheduleProgramId) &&
        selectedFields.length >= 2 &&
        index === 0 &&
        _.isEmpty(selectedFields[1].program.value)
      ) {
        setFeeScheduleProgramId(null);
      }
      selectedFields.removeField(index);
    }
  }

  removeSelectedFieldsExceptIndex(index) {
    const {
      selectedFields,
    } = this.props;

    const providersIds = selectedFields.map((field) => field.group.value.id);
    const toRemoveIds = providersIds.filter((id) => id !== selectedFields[index].group.value.id);
    removeSelectedGroupFields(selectedFields, toRemoveIds);
  }

  addGroupField() {
    const { selectedFields, contact } = this.props;
    const eventPayload = Serializer.build({ contact });
    selectedFields.addField();
    this.checkSensitiveErrors();

    callOrLog(() => this.context.eventTracker(REFERRAL.addAnotherRecipient, { contact: eventPayload }));
  }

  checkSensitiveErrors() {
    const {
      caseReferrals,
      groupKey,
      referral,
      selectedFields,
      suggestedGroups,
      currentUserGroup,
      setHasSensitiveErrors,
      index,
    } = this.props;
    const { hasSensitiveError } = this.state;

    const sensitiveGroups = _.filter(suggestedGroups, (group) => group.sensitive);
    const sensitiveGroupIds = _.map(sensitiveGroups, (g) => g.id);

    const isCurrentUserGroupSensitive = _.get(currentUserGroup, 'sensitive');

    const hasError = selectedFields.some((field) => getInvalidSensitiveErrors({
      caseReferrals,
      sensitiveGroupIds,
      isCurrentUserGroupSensitive,
      fields: selectedFields,
      groupId: field[groupKey].value.id,
      referral,
    }).length > 0);

    if (hasError !== hasSensitiveError) {
      this.setState({ hasSensitiveError: hasError }, () => {
        if (setHasSensitiveErrors) {
          setHasSensitiveErrors(hasError, index);
        }
      });
    }

    return hasError;
  }

  throwSensitiveError() {
    const { setHasSensitiveErrors, index } = this.props;
    const { hasSensitiveError } = this.state;
    const hasError = true;

    if (hasError !== hasSensitiveError) {
      this.setState({ hasSensitiveError: hasError }, () => {
        if (setHasSensitiveErrors) {
          setHasSensitiveErrors(hasError, index);
        }
      });
    }
  }

  render() {
    const {
      allowEmptyGroups,
      registerField,
      caseReferrals,
      canPaginateNetworkGroups,
      ccGroupIds,
      contact,
      currentUserGroup,
      excludeAuthorizationRequired,
      feeScheduleProgramId,
      groupKey,
      originCoordinates,
      hide,
      isFetchingOONGroups,
      isProgramBasedSearch,
      loading,
      programKey,
      referral,
      debouncedSearchNetworkGroups,
      selectedFields,
      serviceTypeField,
      serviceTypeObj,
      shouldSort,
      suggestedGroups,
      nationalStateSuggestedGroups,
      serviceAreaSupportForOrgsFlag,
      query,
      width,
      isFetchingNetworkGroups,
    } = this.props;

    const { disableOrgAddButton, showOrgAddButton } = this.state;
    const sensitiveGroups = _.filter(suggestedGroups, (group) => group.sensitive);
    const sensitiveGroupIds = _.map(sensitiveGroups, (g) => g.id);
    const serviceType = _.isEmpty(serviceTypeObj) ? _.get(serviceTypeField, 'value', {}) : serviceTypeObj;
    const isCurrentUserGroupSensitive = _.get(currentUserGroup, 'sensitive');
    // Do not load the spinner when we are fetching OON groups,
    // because we already have a spinnner for that.
    if (loading && !isFetchingOONGroups) {
      return (
        <div className="service-type-section row">
          <div className="col-xs-12">
            <Spinner scale={0.7} />
          </div>
        </div>
      );
    }

    const fieldErrorsFilter = (field) => getInvalidGroupErrors({
      caseReferrals,
      ccGroupIds,
      fields: selectedFields,
      groupId: field[groupKey].value.id,
      referral,
    }).length > 0;

    const errorsByField = selectedFields
      .filter(fieldErrorsFilter)
      .map((field) => (
        {
          id: field[groupKey].value.id,
          errors: getInvalidGroupErrors({
            caseReferrals,
            ccGroupIds,
            fields: selectedFields,
            groupId: field[groupKey].value.id,
            referral,
          }),
        }
      ))
      .reduce((acc, current) => {
        const result = { ...acc };
        result[current.id] = current.errors;
        return result;
      }, {});

    const sensitiveFieldErrorsFilter = (field) => getInvalidSensitiveErrors({
      caseReferrals,
      sensitiveGroupIds,
      isCurrentUserGroupSensitive,
      fields: selectedFields,
      groupId: field[groupKey].value.id,
      referral,
    }).length > 0;

    const sensitiveErrorsByField = selectedFields
      .filter(sensitiveFieldErrorsFilter)
      .map((field) => (
        {
          id: field[groupKey].value.id,
          errors: getInvalidSensitiveErrors({
            caseReferrals,
            sensitiveGroupIds,
            isCurrentUserGroupSensitive,
            fields: selectedFields,
            groupId: field[groupKey].value.id,
            referral,
          }),
        }
      ))
      .reduce((acc, current) => {
        const result = { ...acc };
        result[current.id] = current.errors;
        return result;
      }, {});
    const sensitiveErrorKey = _.last(Object.keys(sensitiveErrorsByField));
    const browseLinkDisabled = Object.keys(errorsByField).length > 0;

    const groupProgramFields = selectedFields.map((selected, index) => {
      const selectedGroupId = _.get(selected[groupKey], 'value.id');
      const groupIsSelected = !_.isEmpty(selectedGroupId);
      const groupIsCC = _.includes(ccGroupIds, selectedGroupId);
      const field = selected[groupKey];
      const fieldErrors = errorsByField[field.value.id];
      const sensitiveFieldErrors = sensitiveErrorsByField[sensitiveErrorKey];
      const sensitiveErrors = _.get(field, 'value.id') === sensitiveErrorKey ? sensitiveFieldErrors : [];
      const programs = _.get(selected, 'group.value.programs', [])
        .filter(({ name }) => name !== REFERRED_OUT_OF_NETWORK);
      return (
        <div className="row" key={selectedGroupId ?? index}>
          <div className={isProgramBasedSearch ? `col-xs-${width || 12}` : `col-md-6 col-xs-${width || 12}`}>
            {isProgramBasedSearch ? (
              <ReferralProgramGroupSelect
                allowEmptyGroups={allowEmptyGroups}
                groupErrors={_.isEmpty(fieldErrors) ? sensitiveErrors : fieldErrors}
                registerField={registerField}
                debouncedSearchNetworkGroups={debouncedSearchNetworkGroups}
                hidden={hide}
                index={index}
                onProgramGroupSelect={this.onProgramGroupSelect}
                programField={selected[programKey]}
                selectedProgramIds={pluckSelectedProgramIds(selectedFields)}
                serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
                shouldSort={shouldSort}
                suggestedGroups={suggestedGroups}
                referral={referral}
                originCoordinates={originCoordinates}
                groupsOptionType="in-network"
              />
            ) : (
              <ReferralGroupSelect
                allowEmptyGroups={allowEmptyGroups}
                groupErrors={_.isEmpty(fieldErrors) ? sensitiveErrors : fieldErrors}
                registerField={registerField}
                canPaginateNetworkGroups={canPaginateNetworkGroups}
                groupField={selected[groupKey]}
                hidden={hide}
                index={index}
                query={query}
                debouncedSearchNetworkGroups={debouncedSearchNetworkGroups}
                onGroupSelect={this.onGroupSelect}
                selectedGroupIds={pluckSelectedGroupIds(selectedFields, groupKey)}
                shouldSort={shouldSort}
                originCoordinates={originCoordinates}
                suggestedGroups={suggestedGroups}
                nationalStateSuggestedGroups={nationalStateSuggestedGroups}
                serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
                label={'Suggested in Network Organizations'}
              />
            )}
          </div>
          {
            groupIsSelected && !groupIsCC && programs.length > 0 && !isProgramBasedSearch && (
              <div className="col-xs-6 referral-groups-programs__padded-col">
                <ReferralProgramSelect
                  contact={contact}
                  excludeAuthorizationRequired={excludeAuthorizationRequired}
                  index={index}
                  programField={selected[programKey]}
                  selectedGroupId={selectedGroupId}
                  serviceType={serviceType}
                  onProgramSelect={this.onProgramSelect}
                  feeScheduleProgramId={feeScheduleProgramId}
                />
              </div>
            )
          }
        </div>
      );
    });

    const headerWrapper = 'col-md-6 col-xs-12';

    return (
      <div className="referral-groups-programs">
        <div className="row">
          {isProgramBasedSearch ?
          (
            <div className="col-xs-12">
              {!_.isEmpty(serviceTypeField.value) && (
                <AddProgramsSection
                  toggleBrowse={this.props.toggleBrowse}
                  isFetchingNetworkGroups={isFetchingNetworkGroups}
                />
              )}
            </div>
          ) :
          (
            <div className={headerWrapper}>
              <ReferralGroupHeader
                browseLinkDisabled={browseLinkDisabled}
                inNetworkGroupsEmpty={suggestedGroups.length === 0}
                isProgramBasedSearch={isProgramBasedSearch}
                referral={referral}
                serviceTypeField={serviceTypeField}
                toggleBrowse={this.props.toggleBrowse}
                serviceAreaSupportForOrgsFlag={serviceAreaSupportForOrgsFlag}
              />
              <p className="mb-quarter">Sorted by: Distance</p>
            </div>
        )}
        </div>

        {<>{groupProgramFields}</>}

        {!_.isNull(feeScheduleProgramId) && showOrgAddButton && (
          <div data-testid="auth-required-message">
            <p className="my-2 italic">
              This program requires authorization. To send this referral to a provider that shares this program, click
              <strong> Add Another Recipient</strong>.
            </p>
            <p className="my-2 italic">
              To send a referral to a different program, click
              <strong> Add Another Referral</strong> at the bottom of this page.
            </p>
          </div>
        )}

        {
          showOrgAddButton && !hide && (
            <div className="row">
              <div className="col-xs-12">
                <Button
                  className={`mb-one mt-one ${isProgramBasedSearch ? 'mb-20 -mt-2' : ''}`}
                  disabled={disableOrgAddButton}
                  label={`+ ${isProgramBasedSearch ? 'ADD ANOTHER PROGRAM BY QUICK ADD' : 'ADD ANOTHER RECIPIENT'}`}
                  onClick={this.addGroupField}
                />
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

ReferralGroupsPrograms.contextTypes = {
  eventTracker: PropTypes.func.isRequired,
};

ReferralGroupsPrograms.propTypes = {
  allowEmptyGroups: PropTypes.bool,
  caseReferrals: PropTypes.array,
  canPaginateNetworkGroups: PropTypes.bool.isRequired,
  ccGroupIds: PropTypes.array,
  contact: PropTypes.object,
  feeScheduleProgramId: PropTypes.string,
  groupKey: PropTypes.string,
  excludeAuthorizationRequired: PropTypes.bool,
  hide: PropTypes.bool,
  index: PropTypes.number.isRequired,
  isFetchingOONGroups: PropTypes.bool,
  isProgramBasedSearch: PropTypes.bool,
  loading: PropTypes.bool,
  debouncedSearchNetworkGroups: PropTypes.func.isRequired,
  programKey: PropTypes.string,
  referral: PropTypes.object.isRequired,
  originCoordinates: PropTypes.array.isRequired,
  removeSelectedBrowseGroup: PropTypes.func.isRequired,
  registerField: PropTypes.func.isRequired,
  selectedFields: PropTypes.array.isRequired,
  serviceTypeField: PropTypes.object.isRequired,
  serviceTypeObj: PropTypes.object,
  shouldSort: PropTypes.bool,
  suggestedGroups: PropTypes.array.isRequired,
  toggleBrowse: PropTypes.func.isRequired,
  network: PropTypes.object,
  serviceAreaSupportForOrgsFlag: PropTypes.bool,
  nationalStateSuggestedGroups: PropTypes.array,
  query: PropTypes.string,
  width: PropTypes.number,
  currentUserGroup: PropTypes.object.isRequired,
  setHasSensitiveErrors: PropTypes.func.isRequired,
  setFeeScheduleProgramId: PropTypes.func,
  selectedProgramsContext: PropTypes.shape({
    selectedPrograms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })).isRequired,
    dispatchRemoveAllPrograms: PropTypes.func.isRequired,
    dispatchAddProgram: PropTypes.func.isRequired,
    dispatchRemoveProgram: PropTypes.func.isRequired,
  }).isRequired,
  isFetchingNetworkGroups: PropTypes.bool,
};

ReferralGroupsPrograms.defaultProps = {
  allowEmptyGroups: false,
  caseReferrals: [],
  contact: {},
  ccGroupIds: [],
  feeScheduleProgramId: null,
  groupKey: 'group',
  excludeAuthorizationRequired: false,
  hide: true,
  isFetchingOONGroups: false,
  isProgramBasedSearch: false,
  loading: true,
  programKey: 'program',
  shouldSort: false,
  network: {},
  serviceTypeObj: {},
  serviceAreaSupportForOrgsFlag: false,
  nationalStateSuggestedGroups: [],
  query: '',
  width: undefined,
  setFeeScheduleProgramId: () => { },
  isFetchingNetworkGroups: false,
};

export default ReferralGroupsPrograms;
