import React from 'react';
import { get } from 'lodash';
import { Link } from 'react-router';
import { phones as phonesUtils } from '@unite-us/client-utils';
import { useFind, usePopulate } from 'api/APIHooks';
import { Card, CardHeader } from 'common/Card';
import { LineItem } from 'common/display/LineItem';
import { useFeatureFlag } from 'common/hooks';
import moment from 'moment';
import { useCurrentProviderId } from 'common/contexts/CurrentProviderContext';
import InsuranceStatusIndicator from 'components/Referrals/ReferralDetail/components/InsuranceStatusIndicator';
import PropTypes from 'prop-types';
import { epochToDate } from 'common/utils/utils';
import formatDate from 'common/utils/Dates/formatDate';
import findPrimaryNumber from 'common/form/Phone/utils/findPrimaryNumber';
import { hasConsent } from 'components/Contacts/utils';

const ClientDetailsCard = ({ person, serviceAuthorization }) => {
  const includeInternalNonConsentedClients = useFeatureFlag('PAYS-7267-include-internal-nonconsent');
  const isPersonLoaded = !!person?.first_name;

  const providerId = useCurrentProviderId();
  const { data: clientRelationshipData, isSuccess: isClientRelationshipLoaded } = useFind(
    'client_relationship',
    {
      person: person.id,
      provider: providerId,
    },
    {
      queryConfig: {
        enabled: !!person.id && !!providerId,
        placeholderData: undefined,
      },
    },
  );
  const clientRelationships = get(clientRelationshipData, 'data.data', []);
  const hasClientRelationship = clientRelationships.length > 0;

  const primaryPhone = findPrimaryNumber(get(person, 'phone_numbers', []));
  const firstName = get(person, 'first_name');
  const lastName = get(person, 'last_name');
  const dateOfBirth = get(person, 'date_of_birth');
  const formattedDateOfBirth = includeInternalNonConsentedClients ?
    moment(dateOfBirth).format('M/D/YYYY') : epochToDate(dateOfBirth);

  const { isSuccess: isSocialCareCoverageLoaded } = usePopulate(
    'insurance',
    'insurance',
    serviceAuthorization,
    { queryConfig: { placeholderData: undefined } },
  );
  const { isSuccess: isSocialCareCoveragePlanLoaded } = usePopulate(
    'insurance.plan',
    'plan',
    serviceAuthorization,
    { queryConfig: { placeholderData: undefined } },
  );
  const { isSuccess: isPrimaryHealthInsuranceLoaded } = usePopulate(
    'insurance.primary_health_insurance',
    'insurance',
    serviceAuthorization,
    { queryConfig: { placeholderData: undefined } },
  );
  const hasPrimaryHealthInsurance = !!serviceAuthorization?.insurance?.primary_health_insurance;
  const externalMemberID = serviceAuthorization?.insurance?.primary_health_insurance?.external_member_id;

  const enrollmentStatus = serviceAuthorization.insurance?.insurance_status;
  usePopulate(
    'fee_schedule_program',
    'fee_schedule_program',
    serviceAuthorization,
    { queryConfig: { placeholderData: undefined } },
  );
  const { isSuccess: isFeeScheduleLoaded } = usePopulate(
    'fee_schedule_program.fee_schedule',
    'fee_schedule',
    serviceAuthorization,
    { queryConfig: { placeholderData: undefined } },
  );
  const ignoreExpiration = !!serviceAuthorization?.fee_schedule_program?.fee_schedule?.ignore_social_care_expired_dates;

  const { isSuccess: isConsentLoaded } = usePopulate(
    'consent',
    'consent',
    person,
    { queryConfig: { placeholderData: undefined } },
  );

  return (
    <Card className="bg-white">
      <CardHeader
        className="pt-2 pl-2 -pb-2 space-y-2"
        title="Client"
      />
      <dl className="m-0 p-6 grid grid-cols-4 gap-x-8 gap-y-2">
        <LineItem
          field="Name"
          valueClassName="col-span-3 ml-2"
          isLoaded={isPersonLoaded && isClientRelationshipLoaded && (!person.consent || isConsentLoaded)}
          dataTestElement="client-name"
        >
          {(hasClientRelationship && hasConsent(person)) ? (
            <Link to={`/facesheet/${get(person, 'id', '')}`}>
              {firstName} {lastName}
            </Link>
          ) : (
            <>
              {firstName} {lastName}
            </>
          )}
        </LineItem>
        <LineItem
          field="Insurance Member ID"
          valueClassName="col-span-3 ml-2"
          isLoaded={isPrimaryHealthInsuranceLoaded || !hasPrimaryHealthInsurance}
          dataTestElement="client-id"
        >
          {externalMemberID || 'N/A'}
        </LineItem>
        <LineItem
          field="Date of Birth"
          valueClassName="col-span-3 ml-2"
          isLoaded={isPersonLoaded}
          dataTestElement="client-dob"
        >
          {formattedDateOfBirth}
        </LineItem>
        <LineItem
          field="Phone"
          valueClassName="col-span-3 ml-2"
          isLoaded={isPersonLoaded}
          dataTestElement="client-phone"
        >
          {primaryPhone && phonesUtils.formatPhoneNumber(primaryPhone.phone_number)}
        </LineItem>
        <LineItem
          field="Social Care Coverage Plan"
          valueClassName="col-span-3 ml-2"
          isLoaded={isSocialCareCoveragePlanLoaded}
          dataTestElement="client-insurance-plan-name"
        >
          <div className="leading-5">
            {serviceAuthorization.insurance?.plan?.name}
            <br />
            {formatDate(serviceAuthorization.insurance?.enrolled_at)}
            {serviceAuthorization.insurance?.expired_at ?
              ` - ${formatDate(serviceAuthorization.insurance?.expired_at)}` :
              ' - present'}
          </div>
        </LineItem>
        <LineItem
          field="Social Care Coverage Status"
          valueClassName="col-span-3 ml-2"
          isLoaded={isSocialCareCoverageLoaded && isFeeScheduleLoaded}
          dataTestElement="client-insurance-status"
        >
          {() => (
            <InsuranceStatusIndicator
              ignoreExpiration={ignoreExpiration}
              showTooltip={false}
              status={enrollmentStatus}
            />
          )}
        </LineItem>
      </dl>
    </Card>
  );
};

ClientDetailsCard.propTypes = {
  person: PropTypes.object.isRequired,
  serviceAuthorization: PropTypes.object.isRequired,
};

export default ClientDetailsCard;
