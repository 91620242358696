/* eslint-disable max-len */
import React from 'react';
import { IndexRoute, IndexRedirect, Route, Redirect } from 'react-router';
import AllClients from 'pages/dashboard/clients/all/AllClients';
import DuplicateClients from 'src/pages/people/duplicates/DuplicateClients';
import AllScreenings from 'pages/dashboard/screenings/all/_screening_id/AllScreenings';
import AllNetworkCases from 'src/pages/dashboard/cases/all-network/AllNetworkCases';
import AllOONCases from 'pages/dashboard/cases/out-of-network/_id/contact/_contact_id/AllOONCases';
import AllExternalCases from 'pages/dashboard/cases/external/_id/contact/_contact_id/AllExternalCases';
import ARReferralsAddSupportingDetails from 'pages/assistance-requests/_assistance_request_id/referrals/new/add-supporting-details/ARReferralsAddSupportingDetails';
import ARContactsNewReview from 'pages/assistance-requests/_assistance_request_id/contacts/new/review/ARContactsNewReview';
import ARContactCasesAddAssessments from 'pages/assistance-requests/_assistance_request_id/contacts/_id/cases/new/add-case-assessments/ARContactCasesAddAssessments';
import ARContactNewCaseReview from 'pages/assistance-requests/_assistance_request_id/contacts/_id/cases/new/review/ARContactNewCaseReview';
import ARContactCasesNewDetails from 'pages/assistance-requests/_assistance_request_id/contacts/_id/cases/new/add-case-details/ARContactCasesNewDetails';
import ARContactCasesNewView from 'pages/assistance-requests/_assistance_request_id/contacts/_id/cases/new/ARContactCasesNewView';
import ARReferralsContactReview from 'pages/assistance-requests/_assistance_request_id/referrals/new/review/ARReferralsContactReview';
import ARReferralFinalReview from 'pages/assistance-requests/_assistance_request_id/referrals/new/final-review/ARReferralFinalReview';
import ARReferralServicesForm from 'pages/assistance-requests/_assistance_request_id/referrals/new/add-service-types/ARReferralServicesForm';
import AssistanceRequestsClosed from 'pages/dashboard/assistance-requests/closed/_id/Closed';
import AssistanceRequestsProcessed from 'pages/dashboard/assistance-requests/processed/_id/Processed';
import ServiceAuthorizationsContainer from 'pages/service-authorizations/ServiceAuthorizationsContainer';
import ServiceAuthorizationDetails from 'pages/service-authorizations/_id/ServiceAuthorizationDetails';
import ServiceAuthorizationAssessmentDetails from 'pages/service-authorizations/_id/ServiceAuthorizationAssessmentDetails';
import BackOffice from 'pages/backoffice/BackOffice';
import Payers from 'pages/backoffice/payers/Payers';
import PayerForm from 'pages/backoffice/payers/_id/PayerForm';
import Payer from 'pages/backoffice/payers/_id/Payer';
import Forms from 'pages/backoffice/forms/Forms';
import FormEdit from 'pages/backoffice/forms/_id/FormEdit';
import FormNew from 'pages/backoffice/forms/FormNew';
import CasesAddAssessments from 'pages/cases/_id/new/add-case-assessments/CasesAddAssessments';
import CasesAllCases from 'pages/dashboard/cases/all/_id/contact/_contact_id/AllCases';
import CasesClosedCases from 'pages/dashboard/cases/closed/_id/contact/_contact_id/ClosedCases';
import CasesNewAddDetails from 'pages/cases/_id/new/add-case-details/CasesNewAddDetails';
import CasesNewView from 'pages/cases/_id/new/CasesNewView';
import CasesNewReview from 'pages/cases/_id/new/review/CasesNewReview';
import CasesOONCases from 'pages/dashboard/cases/out-of-network/_id/contact/_contact_id/OONCases';
import ClosedExternalCases from 'pages/dashboard/cases/external/_id/contact/_contact_id/ClosedExternalCases';
import ClosedOONCases from 'pages/dashboard/cases/out-of-network/_id/contact/_contact_id/ClosedOONCases';
import CaseLegacyDetailRedirect from 'pages/facesheet/_id/cases/_case_id/CaseLegacyDetailRedirect';
import CasesOpenCases from 'pages/dashboard/cases/open/_id/contact/_contact_id/OpenCases';
import ClosedReferrals from 'pages/dashboard/referrals/sent/closed/_id/ClosedReferrals';
import ContactNewAddCaseAssessments from 'pages/contacts/_id/cases/new/add-case-assessments/ContactNewAddCaseAssessments';
import CasesAssessmentDetailsView from 'pages/cases/_id/assessments/_assessment_id/CasesAssessmentDetailsView';
import ContactCasesNewView from 'pages/contacts/_id/cases/new/ContactCasesNewView';
import ContactNewAddCaseDetails from 'pages/contacts/_id/cases/new/add-case-details/ContactNewAddCaseDetails';
import ContactNewCaseReview from 'pages/contacts/_id/cases/new/review/ContactNewCaseReview';
import ContactsNewReview from 'pages/contacts/new/review/ContactsNewReview';
import ContactScreeningDetailsView from 'pages/facesheet/_id/screenings/_assessment_id/submissions/new/ContactScreeningDetailsView';
import ContactAssessmentDetailsView from 'pages/contacts/_id/assessment/_id/ContactAssessmentDetailsView';
import CreateFeeScheduleProgram from 'pages/fee-schedules/_id/FeeSchedulePrograms/CreateFeeScheduleProgram';
import CreatePlan from 'pages/backoffice/payers/_id/plans/CreatePlan';
import RequestOrUploadConsent from 'pages/consent/_contactId/_itemType/RequestOrUploadConsent';
import DraftReferrals from 'pages/dashboard/referrals/sent/drafts/_id/DraftReferrals';
import EditFeeScheduleProgram from 'pages/fee-schedules/_id/FeeSchedulePrograms/_fee_schedule_program_id/EditFeeScheduleProgram';
import Plan from 'pages/backoffice/payers/_id/plans/_plan_id/Plan';
import EditPlan from 'pages/backoffice/payers/_id/plans/_plan_id/EditPlan';
import EnrollmentDetailPage from 'pages/enrollments/components/DetailPage/EnrollmentDetailPage';
import EnrollmentsContainer from 'pages/enrollments/EnrollmentsContainer';
import EnrollmentAssessmentDetailsView from 'pages/enrollments/components/EnrollmentAssessmentDetailsView';
import EulaAgreement from 'pages/account/EulaAgreement';
import Exports from 'pages/exports/Exports';
import ExternalCases from 'pages/dashboard/cases/external/_id/contact/_contact_id/ExternalCases';
import Facesheet from 'pages/facesheet/Facesheet';
import FacesheetAssessments from 'pages/facesheet/_id/forms/FacesheetAssessments';
import EligibilityAssessmentsRoute from 'pages/facesheet/_id/eligibility/EligibilityAssessmentsRoute';
import NewEligibilityAssessment from 'pages/facesheet/_id/eligibility/NewAssessment';
import ViewEligibilityAssessment from 'pages/facesheet/_id/eligibility/ViewEligibilityAssessment';
import EligibilityAssessments from 'pages/facesheet/_id/eligibility/EligibilityAssessments';
import FacesheetScreenings from 'pages/facesheet/_id/screenings/FacesheetScreenings';
import FacesheetCases from 'pages/facesheet/_id/cases/FacesheetCases';
import FacesheetEnrollmentFormsContainer from 'pages/facesheet/_id/enrollments/FacesheetEnrollmentFormsContainer';
import FacesheetOverview from 'pages/facesheet/_id/FacesheetOverview';
import FacesheetProfile from 'pages/facesheet/_id/profile/FacesheetProfile';
import FacesheetReferrals from 'pages/facesheet/_id/referrals/FacesheetReferrals';
import FacesheetScreeningDetail from 'pages/facesheet/_id/forms/screenings/_screening_id/FacesheetScreeningDetail';
import FeeSchedules from 'pages/fee-schedules/FeeSchedules';
import FeeSchedule from 'pages/fee-schedules/_id/FeeSchedule';
import EditFeeSchedule from 'src/pages/fee-schedules/_id/EditFeeSchedule';
import CreateFeeSchedule from 'src/pages/fee-schedules/CreateFeeSchedule';
import FeeScheduleProgram from 'pages/fee-schedules/_id/FeeSchedulePrograms/_fee_schedule_program_id/FeeScheduleProgram';
import FeeScheduleScreening from 'pages/fee-schedules/_id/FeeScheduleScreenings/_fee_schedule_screening_id/FeeScheduleScreening';
import FeeScheduleScreeningNew from 'pages/fee-schedules/_id/FeeScheduleScreenings/_fee_schedule_screening_id/FeeScheduleScreeningNew';
import GroupReports from 'pages/reports/group/GroupReports';
import Home from 'pages/Home';
import Insights from 'pages/insights/Insights';
import Tasks from 'pages/tasks/Tasks';
import AcceptedInvoicesDWQ from 'pages/invoices/pages/AcceptedInvoicesDWQ';
import AllInvoices from 'pages/invoices/pages/AllInvoices';
import ArchivedInvoicesDWQ from 'pages/invoices/pages/ArchivedInvoicesDWQ';
import ClosedInvoices from 'pages/invoices/pages/ClosedInvoices';
import DisputedInvoices from 'pages/invoices/pages/DisputedInvoices';
import DraftInvoices from 'pages/invoices/pages/DraftInvoices';
import InvoiceDetailPage from 'pages/invoices/components/InvoiceDetailPage';
import InvoicesContainer from 'pages/invoices/InvoicesContainer';
import OpenInvoices from 'pages/invoices/pages/OpenInvoices';
import RejectedInvoicesDWQ from 'pages/invoices/pages/RejectedInvoicesDWQ';
import SubmittedInvoicesDWQ from 'pages/invoices/pages/SubmittedInvoicesDWQ';
import OAuthLogin from 'pages/callback/OAuthLogin';
import MilitaryReport from 'pages/reports/group/_id/military/MilitaryReport';
import Network from 'pages/network/Network';
import NetworkBrowse from 'pages/network/_id/browse/NetworkBrowse';
import NetworkUsers from 'pages/network/_id/users/NetworkUsers';
import NetworkGroups from 'pages/network/_id/orgs/NetworkGroups';
import NewAssistanceRequests from 'pages/dashboard/new/assistance-requests/_id/NewAssistanceRequests';
import GroupsInvitationsNew from 'pages/invitations/new/GroupsInvitationsNew';
import NewReferrals from 'pages/dashboard/new/referrals/_id/NewReferrals';
import NewScreeningReviewContactDetails from 'pages/screenings/new/review/NewScreeningReviewContactDetails';
import OrganizationContainerLazyLoader from 'src/pages/organization/settings/OrganizationContainerLazyLoader';
import Organization from 'src/pages/organization/settings';
import OrganizationEdit from 'src/pages/organization/settings/Edit';
import OrganizationUsers from 'src/pages/organization/settings/users';
import OrganizationUserDetail from 'src/pages/organization/settings/users/_id';
import OrganizationLocationNew from 'src/pages/organization/settings/locations/_id/New';
import OrganizationLocationEdit from 'src/pages/organization/settings/locations/_id/Edit';
import OrganizationProgramNew from 'src/pages/organization/settings/programs/New';
import OrganizationProgramEdit from 'src/pages/organization/settings/programs/_id/Edit';
import OrganizationUserNew from 'src/pages/organization/settings/users/New';
import OrganizationUserEdit from 'src/pages/organization/settings/users/_id/Edit';
import MergeSelect from 'pages/people/merge-select/MergeSelect';
import MergeDetails from 'pages/people/merge-details/MergeDetails';
import PopulationReport from 'pages/reports/group/_id/population/PopulationReport';
import PrdOverview from 'pages/backoffice/prds/_id/prd_details/_prd_detail_id/PrdOverview';
import PrdTable from 'pages/backoffice/prds/PrdTable';
import CreatePrd from 'pages/backoffice/prds/CreatePrd';
import EditDirectoryConfiguration from 'pages/backoffice/prds/_id/prd_details/_prd_detail_id/EditDirectoryConfiguration';
import EditPrd from 'pages/backoffice/prds/_id/EditPrd';
import ProviderToProviderReferrals from 'pages/dashboard/referrals/provider-to-provider/_id/ProviderToProviderReferrals';
import RecalledReferrals from 'pages/dashboard/referrals/recalled/_id/RecalledReferrals';
import ReferralsAddSupportingDetails from 'pages/referrals/new/add-supporting-details/ReferralsAddSupportingDetails';
import ReferralAssessmentDetailsView from 'pages/referrals/_id/assessments/_assessment_id/ReferralAssessmentDetailsView';
import ReferralLegacyDetailRedirect from 'pages/referrals/drafts/_id/ReferralLegacyDetailRedirect';
import ReferralMilitaryInformation from 'pages/referrals/_id/military-information/_contact_id/ReferralMilitaryInformation';
import ReferralsContactReview from 'pages/referrals/new/review/ReferralsContactReview';
import ReferralsFinalReview from 'pages/referrals/new/final-review/ReferralsFinalReview';
import ReferralNewServicesForm from 'pages/referrals/new/add-service-types/ReferralNewServicesForm';
import ReferralsInReview from 'pages/dashboard/referrals/in-review/_id/ReferralsInReview';
import ClosedInboundReferrals from 'pages/dashboard/new/closed/_id/ClosedInboundReferrals';
import RejectedReferrals from 'pages/dashboard/referrals/rejected/_id/RejectedReferrals';
import ResourceLists from 'pages/resourceLists/ResourceLists';
import RevokeConsent from 'pages/backoffice/revoke-consent/RevokeConsent';
import { ScreeningDetailHOC } from 'pages/components';
import ScreeningAddSupportingDetails from 'pages/screenings/_screening_id/need/_need_id/referrals/new/add-supporting-details/ScreeningAddSupportingDetails';
import ScreeningContactReview from 'pages/screenings/_screening_id/need/_need_id/referrals/new/review/ScreeningContactReview';
import ScreeningNeedReferralAddContactInfo from 'pages/screenings/_screening_id/need/_need_id/referrals/new/add-contact-information/ScreeningNeedReferralAddContactInfo';
import ScreeningNewFormWrapper from 'pages/screenings/new/screening/ScreeningNewFormWrapper';
import ScreeningSearchContactStep from 'pages/screenings/_screening_id/need/_need_id/referrals/new/search/ScreeningSearchContactStep';
import Search from 'pages/search/Search';
import ScreeningToReferral from 'pages/screenings/_screening_id/need/_need_id/referrals/new/add-service-types/ScreeningToReferral';
import SendReferralCard from 'pages/referrals/_id/send/SendReferralCard';
import ScreeningConfirmationStep from 'pages/screenings/_screening_id/need/_need_id/referrals/new/confirm/ScreeningConfirmationStep';
import ScreeningFinalReview from 'pages/screenings/_screening_id/need/_need_id/referrals/new/final-review/ScreeningFinalReview';
import SentReferrals from 'pages/dashboard/referrals/sent/SentReferrals';
import ServicesReport from 'pages/reports/group/_id/services/ServicesReport';
import TokenLoginView from 'pages/impersonate/TokenLoginView';
import Uploads from 'pages/facesheet/_id/uploads/Uploads';
import VerbalConsentScript from 'pages/consent/verbal-consent-script/VerbalConsentScript';
import UserSettings from 'pages/user/settings/UserSettings';
import UserAccountInformation from 'pages/user/settings/UserAccountInformation';
import NotificationPreferencesForm from 'src/components/NotificationPreferences/NotificationPreferencesForm';
import { DASHBOARD_EVENTS } from 'common/utils/EventTracker/utils/eventConstants';
import {
  programBasedSearchSelector,
  showResourceLists,
  uup459SupersetPhase2,
  crtb676CloseInboundReferrals,
  hint542SupersetUnlistedPrograms,
  scrn7ScreeningsCompletion,
  scrn655inboundScreeningsDashboard,
} from 'common/utils/FeatureFlags/flags';
import NewItemStepper from 'common/display/NewItemStepper';
import {
  ContactSearch,
  ContactConfirmation,
  AddContactInformation,
} from 'common/display/NewItemStepper/components';
import App from 'src/components/App/components/App';
import ContactMilitaryInformation from 'src/components/MilitaryInformation/components/ContactMilitaryInformation';
import Dashboard from 'src/components/Dashboard';
import AllServiceAuthorizations from 'pages/service-authorizations/pages/AllServiceAuthorizations';
import OpenServiceAuthorizations from 'pages/service-authorizations/pages/OpenServiceAuthorizations';
import AcceptedServiceAuthorizations from 'pages/service-authorizations/pages/AcceptedServiceAuthorizations';
import RejectedServiceAuthorizations from 'pages/service-authorizations/pages/RejectedServiceAuthorizations';
import AllEnrollments from 'pages/enrollments/pages/AllEnrollments';
import OpenEnrollments from 'pages/enrollments/pages/OpenEnrollments';
import AcceptedEnrollments from 'pages/enrollments/pages/AcceptedEnrollments';
import RejectedEnrollments from 'pages/enrollments/pages/RejectedEnrollments';
import CanceledEnrollments from 'pages/enrollments/pages/CanceledEnrollments';
import MessageCenter from 'src/pages/message-center/pages/MessageCenter';
import MyNetworkPrograms from 'src/pages/network/_id/MyNetworkPrograms';
import ExternalScreenings from 'pages/dashboard/screenings/external/ExternalScreenings';
import Referrals from './referrals/2/Referrals';
import SupersetSearch from '../components/Search/SupersetSearch';
import Screenings from './screenings/Screenings';

/**
 * uuids will be mapped to Jira project keys in datadog log ingest.
 * uuid generator for additional owners: https://www.uuidgenerator.net/
 *  */
const UU_OWNERS = {
  resourcesExternal: '9a19bba2-2dd1-4cb1-8df4-7ea63edb8e32', // HINT
  resourcesPlatform: 'd04f62bc-6742-4d26-bca0-edd5064a382e', // CERB
  casesAndReferrals: '87607c22-6ab9-4a0b-9a45-831c18864244', // CRTB
  screenings: '5a68f017-2f0a-4528-8992-b6ef8eceb3cd', // SCRN
};

export default function configAuthenticatedRoutes({ getState }) {
  const catchAllLoggedInRedirectPath = '/';
  const oonLabel = hint542SupersetUnlistedPrograms(getState()) ? 'OFF-PLATFORM CASES' : 'OUT OF NETWORK CASES';
  return (
    <Route>
      <Route path="account" component={EulaAgreement} />
      <Route path="callback" component={OAuthLogin} />
      <Route path="impersonate" component={TokenLoginView} />
      <Route path="/" component={App}>
        <IndexRoute component={Home} title="Home" />
        <Route path="dashboard" component={Dashboard} title="Dashboard">
          <Route path="new" label="INBOUND REFERRALS" uuOwnedBy={UU_OWNERS.casesAndReferrals}>
            <Route
              path="referrals(/:id)"
              label="Needs Action"
              component={NewReferrals}
              countPath="referrals.received.pending"
              eventConstant={DASHBOARD_EVENTS.navNewReferrals}
            />
            <Route
              path="in-review(/:id)"
              label="In Review"
              component={ReferralsInReview}
              countPath="referrals.received.in_review"
              eventConstant={DASHBOARD_EVENTS.navReferralsInReview}
            />
            <Route
              path="closed(/:id)"
              label="Closed"
              component={ClosedInboundReferrals}
              eventConstant={DASHBOARD_EVENTS.navClosedInboundReferrals}
              onEnter={(_params, replace) => {
                if (!crtb676CloseInboundReferrals(getState())) {
                  replace('/dashboard');
                }
              }}
            />
          </Route>
          <Route path="assistance-requests" label="ASSISTANCE REQUESTS" uuOwnedBy={UU_OWNERS.casesAndReferrals}>
            <Route
              path="new(/:id)"
              label="Needs Action"
              component={NewAssistanceRequests}
              countPath="assistance_requests.pending"
              eventConstant={DASHBOARD_EVENTS.navNewAssistanceRequest}
            />
            <Route
              path="closed(/:id)"
              label="Closed"
              component={AssistanceRequestsClosed}
              eventConstant={DASHBOARD_EVENTS.navAssistanceRequestClosed}
            />
            <Route
              path="processed(/:id)"
              label="Processed"
              component={AssistanceRequestsProcessed}
              eventConstant={DASHBOARD_EVENTS.navAssistanceRequestProcesssed}
            />
          </Route>
          <Route path="cases" label="INTERNAL CASES" uuOwnedBy={UU_OWNERS.casesAndReferrals}>
            <Route
              path="open(/:id/contact/:contact_id)"
              label="Open"
              component={CasesOpenCases}
              eventConstant={DASHBOARD_EVENTS.navCasesOpen}
            />
            <Route
              path="closed(/:id/contact/:contact_id)"
              label="Closed"
              component={CasesClosedCases}
              eventConstant={DASHBOARD_EVENTS.navCasesClosed}
            />
            <Route
              path="all(/:id/contact/:contact_id)"
              label="All"
              component={CasesAllCases}
              eventConstant={DASHBOARD_EVENTS.navCasedAll}
            />
          </Route>
          <Route path="screenings" label="INBOUND SCREENINGS" uuOwnedBy={UU_OWNERS.screenings}>
            <Route
              path="received"
              label="Received"
              component={ExternalScreenings}
              countPath="screenings.external"
              eventConstant={DASHBOARD_EVENTS.navScreeningsExternal}
              onEnter={
                (_params, replace) => {
                  const state = getState();
                  if (!scrn7ScreeningsCompletion(state) || !scrn655inboundScreeningsDashboard(state)) {
                    replace('/');
                  }
                }
              }
            />
          </Route>
          <Route path="referrals/sent" label="OUTBOUND REFERRALS" uuOwnedBy={UU_OWNERS.casesAndReferrals}>
            <Route
              path="draft(/:id/contact/:contact_id)"
              label="Drafts"
              component={DraftReferrals}
              eventConstant={DASHBOARD_EVENTS.navReferralsDrafts}
            />
            <Route
              path="rejected(/:id)"
              label="Rejected"
              component={RejectedReferrals}
              countPath="referrals.sent.rejected"
              eventConstant={DASHBOARD_EVENTS.navReferralsRejected}
            />
            <Route
              path="recalled(/:id)"
              label="Recalled"
              component={RecalledReferrals}
              countPath="referrals.sent.recalled"
              eventConstant={DASHBOARD_EVENTS.navReferralsRecalled}
            />
            <Route
              path="needs-action(/:id)"
              label="Needs Action"
              status="pending"
              component={SentReferrals}
              eventConstant={DASHBOARD_EVENTS.navReferralsSentNeedsAction}
            />
            <Route
              path="in-review(/:id)"
              label="In Review"
              status="in_review"
              component={SentReferrals}
              eventConstant={DASHBOARD_EVENTS.navReferralsSentInReview}
            />
            <Route
              path="closed(/:id)"
              label="Closed"
              component={ClosedReferrals}
              eventConstant={DASHBOARD_EVENTS.navReferralsClosed}
            />
            <Route
              path="all(/:id)"
              label="All"
              status="all"
              component={SentReferrals}
              eventConstant={DASHBOARD_EVENTS.navReferralsSentAll}
            />
          </Route>
          <Route path="external-cases" label="EXTERNAL CASES" uuOwnedBy={UU_OWNERS.casesAndReferrals}>
            <Route
              path="open(/:id/contact/:contact_id)"
              label="Open"
              component={ExternalCases}
              eventConstant={DASHBOARD_EVENTS.navCasesExternal}
            />
            <Route
              path="closed(/:id/contact/:contact_id)"
              label="Closed"
              component={ClosedExternalCases}
              eventConstant={DASHBOARD_EVENTS.navClosedExternalCases}
            />
            <Route
              path="all(/:id/contact/:contact_id)"
              label="All"
              component={AllExternalCases}
              eventConstant={DASHBOARD_EVENTS.navCasesAllExternal}
            />
          </Route>
          <Route path="oon-cases" label={oonLabel} uuOwnedBy={UU_OWNERS.casesAndReferrals}>
            <Route
              path="open(/:id/contact/:contact_id)"
              label="Open"
              component={CasesOONCases}
              eventConstant={DASHBOARD_EVENTS.navCasesOON}
            />
            <Route
              path="closed(/:id/contact/:contact_id)"
              label="Closed"
              component={ClosedOONCases}
              eventConstant={DASHBOARD_EVENTS.navClosedOONCases}
            />
            <Route
              path="all(/:id/contact/:contact_id)"
              label="All"
              component={AllOONCases}
              eventConstant={DASHBOARD_EVENTS.navCasesAllOON}
            />
          </Route>
          <Route path="all-network" label="ALL NETWORK">
            <Route
              path="referrals(/:id)"
              label="Referrals"
              component={ProviderToProviderReferrals}
              eventConstant={DASHBOARD_EVENTS.navReferralsProviderToProvider}
            />
            <Route
              path="cases(/:id/contact/:contact_id)"
              label="Cases"
              component={AllNetworkCases}
              eventConstant={DASHBOARD_EVENTS.navCasedAll}
            />
          </Route>
        </Route>
        <Route path="dashboard/clients">
          <Route
            path="all"
            label="All"
            component={AllClients}
            eventConstant={DASHBOARD_EVENTS.navClientsAll}
          />
        </Route>
        <Route path="screenings" label="Screenings">
          <Route
            path="all(/:screening_id)"
            label="All"
            component={AllScreenings}
            eventConstant={DASHBOARD_EVENTS.navScreeningsAll}
          />
        </Route>
        <Route path="people">
          <Route path="duplicates/:person_id" component={MergeSelect} />
          <Route path="duplicates" component={DuplicateClients} />
          <Route path="merge" component={MergeDetails} />
        </Route>
        <Route path="cases/:id" title="Cases">
          <Route path="assessments/:assessment_id/submissions/new" component={CasesAssessmentDetailsView} />
          <Route path="assessments/:assessment_id/submissions/:submission_id" component={CasesAssessmentDetailsView} />
          <Route path="new" component={CasesNewView} title="New case">
            <Route path="add-case-details" component={CasesNewAddDetails} />
            <Route path="add-case-assessments" component={CasesAddAssessments} />
            <Route path="review" component={CasesNewReview} />
          </Route>
        </Route>

        <Route path="referrals/2/*" component={Referrals} />
        <Route path="referrals" title="Referrals">
          <Route path="(drafts/):id" component={ReferralLegacyDetailRedirect} />
          <IndexRedirect to="/dashboard/new/referrals" />
          <Route path=":id/military-information/:contact_id" component={ReferralMilitaryInformation} />
          <Route path=":id/assessments/:assessment_id/submissions/new" component={ReferralAssessmentDetailsView} />
          <Route path=":id/assessments/:assessment_id/submissions/:submission_id" component={ReferralAssessmentDetailsView} />
          <Route path=":id/send" component={SendReferralCard} />
          <Route path="new" component={NewItemStepper} title="Create Referral">
            <Route path="search" component={ContactSearch} />
            <Route path="confirm" component={ContactConfirmation} />
            <Route path="add-contact-information" from="referral" component={AddContactInformation} />
            <Route path="review" component={ReferralsContactReview} />
            <Route path="add-service-types" component={ReferralNewServicesForm} uuOwnedBy={UU_OWNERS.casesAndReferrals} />
            <Route path="add-supporting-details" component={ReferralsAddSupportingDetails} uuOwnedBy={UU_OWNERS.casesAndReferrals} />
            <Route path="final-review" component={ReferralsFinalReview} uuOwnedBy={UU_OWNERS.casesAndReferrals} />
          </Route>
          <Route path="create">
            <Route
              path="add-resources" title="Add Resources" component={SupersetSearch}
              onEnter={(_params, replace) => {
                if (!uup459SupersetPhase2(getState())) {
                  replace('/dashboard');
                }
              }}
              uuOwnedBy={UU_OWNERS.resourcesPlatform}
            />
          </Route>
        </Route>

        <Route path="consent/verbal-consent-script" component={VerbalConsentScript} />
        <Route path="consent/:personId/:itemType" component={RequestOrUploadConsent} />

        <Route path="assistance-requests/:assistance_request_id">
          <IndexRedirect to="/dashboard/new/assistance-requests/:assistance_request_id" />
          <Route path="assessments/:assessment_id/submissions/new" component={CasesAssessmentDetailsView} />
          <Route path="assessments/:assessment_id/submissions/:submission_id" component={CasesAssessmentDetailsView} />
          <Route
            path="contacts/new"
            component={NewItemStepper}
            title="Create Client"
          >
            <Route path="add-contact-information" from="case-ar" component={AddContactInformation} />
            <Route path="confirm" component={ContactConfirmation} />
            <Route path="review" component={ARContactsNewReview} />
            <Route path="search" component={ContactSearch} />
          </Route>
          <Route path="contacts/:id/cases">
            <Route path="new" component={ARContactCasesNewView} title="Create Case">
              <Route path="add-case-details" component={ARContactCasesNewDetails} />
              <Route path="add-case-assessments" component={ARContactCasesAddAssessments} />
              <Route path="review" component={ARContactNewCaseReview} />
            </Route>
          </Route>
          <Route path="referrals/new" component={NewItemStepper} title="Create Referral">
            <Route path="add-contact-information" from="referral-ar" component={AddContactInformation} />
            <Route path="add-service-types" component={ARReferralServicesForm} />
            <Route path="add-supporting-details" component={ARReferralsAddSupportingDetails} />
            <Route path="confirm" component={ContactConfirmation} />
            <Route path="final-review" component={ARReferralFinalReview} />
            <Route path="review" component={ARReferralsContactReview} />
            <Route path="search" component={ContactSearch} />
          </Route>
        </Route>

        <Route path="invitations/new" component={GroupsInvitationsNew} />

        <Route path="contacts/new" component={NewItemStepper} title="Create Client">
          <Route path="add-contact-information" from="nav" component={AddContactInformation} />
          <Route path="confirm" component={ContactConfirmation} />
          <Route path="review" component={ContactsNewReview} />
          <Route path="search" component={ContactSearch} />
        </Route>

        <Route path="contacts/:id">
          <IndexRedirect to="/facesheet/:id" />
          <Route path="cases">
            <Route path="new" component={ContactCasesNewView} title="Create Case">
              <Route path="add-case-details" component={ContactNewAddCaseDetails} />
              <Route path="add-case-assessments" component={ContactNewAddCaseAssessments} />
              <Route path="review" component={ContactNewCaseReview} />
            </Route>
          </Route>
          <Route path="assessments/:assessment_id" component={ContactAssessmentDetailsView} />
        </Route>

        <Route path="facesheet" component={Facesheet} title="Face Sheet">
          <Route path=":id" component={FacesheetOverview} />
          <Route path=":id/cases" component={FacesheetCases} />
          <Route path=":id/cases/:case_id" component={CaseLegacyDetailRedirect} />
          <Route path=":id/screenings" component={FacesheetScreenings}>
            <Route path=":assessment_id/submissions/new" component={ContactScreeningDetailsView} />
          </Route>
          <Route path=":id/eligibility" component={EligibilityAssessmentsRoute}>
            <IndexRoute component={EligibilityAssessments} />
            <Route path="all" component={EligibilityAssessments} />
            <Route path="view/:assessment_id" component={ViewEligibilityAssessment} />
            <Route path="new(/:assessment_id)(/:step)" component={NewEligibilityAssessment} />
            <Route mode="edit" path="edit/:assessment_id(/:step)" component={NewEligibilityAssessment} />
          </Route>
          <Route path=":id/forms" component={FacesheetAssessments}>
            <Route path=":assessment_id/submissions/new" component={ContactAssessmentDetailsView} />
            <Route path=":assessment_id/submissions/:submission_id" component={ContactAssessmentDetailsView} />
            <Route path="screenings/:screening_id" component={FacesheetScreeningDetail} />
          </Route>
          <Route path=":id/profile" component={FacesheetProfile} />
          <Route path=":id/profile/enrollment-forms" component={FacesheetEnrollmentFormsContainer} />
          <Route path=":id/referrals" component={FacesheetReferrals} />
          <Route path=":id/uploads" component={Uploads} />
          <Route
            path=":id/resource-lists*"
            component={ResourceLists}
            onEnter={
              (_params, replace) => {
                const state = getState();
                if (!showResourceLists(state)) {
                  replace('/');
                }
              }
            }
            uuOwnedBy={UU_OWNERS.resourcesPlatform}
          />
        </Route>

        <Route
          path="network/:networkId"
          component={MyNetworkPrograms}
          onEnter={
            ({ params: { networkId } }, replace) => {
              if (!programBasedSearchSelector(getState())) {
                replace(`/network/${networkId}/browse`);
              }
              if (uup459SupersetPhase2(getState())) {
                replace('/browse-resources');
              }
            }
          }
          uuOwnedBy={UU_OWNERS.resourcesPlatform}
        />

        <Route
          path="browse-resources"
          component={Network}
          title="Network Profile"
          onEnter={(_params, replace) => {
            if (!uup459SupersetPhase2(getState())) {
              replace('/dashboard');
            }
          }}
          uuOwnedBy={UU_OWNERS.resourcesPlatform}
        >
          <IndexRoute component={MyNetworkPrograms} />
          <Route path="browse" component={MyNetworkPrograms} />
          <Route path="orgs" component={NetworkGroups} />
          <Route path="users" component={NetworkUsers} />
        </Route>

        <Route
          path="network"
          component={Network}
          title="Network Profile"
          onEnter={(_params, replace) => {
            if (uup459SupersetPhase2(getState())) {
              replace('/browse-resources');
            }
          }}
          uuOwnedBy={UU_OWNERS.resourcesPlatform}
        >
          <Route path=":networkId/browse" component={NetworkBrowse} />
          <Route path=":networkId/orgs" component={NetworkGroups} />
          <Route path=":networkId/users" component={NetworkUsers} />
        </Route>

        <Route path="user/settings" component={UserSettings} title="Settings">
          <IndexRoute component={UserAccountInformation} />
          <Route path="notifications" component={NotificationPreferencesForm} />
        </Route>

        <Route path="military-information/:id" component={ContactMilitaryInformation} />

        <Route path="workflow-dashboard" title="Workflow Dashboard">
          <IndexRedirect to="/dashboard" />
          <Redirect from="*" to="/dashboard" />
        </Route>

        <Route path="search" component={Search} title="Search Results" />

        <Route
          path="screenings/v2*"
          component={Screenings}
          label="All"
          onEnter={
            (_params, replace) => {
              const state = getState();
              if (!scrn7ScreeningsCompletion(state)) {
                replace('/');
              }
            }
          }
        />

        <Route path="screenings" title="Client Screening">
          <Route path="new" component={NewItemStepper} title="Create Client Screening">
            <Route path="search" component={ContactSearch} />
            <Route path="confirm" component={ContactConfirmation} />
            <Route path="review" component={NewScreeningReviewContactDetails} />
            <Route path="add-contact-information" from="screening" component={AddContactInformation} />
            <Route path="screening" component={ScreeningNewFormWrapper} />
          </Route>
          <Route path=":screening_id" component={ScreeningDetailHOC} />
          <Route path=":screening_id/need/:need_id/referrals/new" component={NewItemStepper} title="Create Referral">
            <Route path="add-contact-information" from="screenings" component={ScreeningNeedReferralAddContactInfo} />
            <Route path="add-service-types" component={ScreeningToReferral} />
            <Route path="add-supporting-details" component={ScreeningAddSupportingDetails} />
            <Route path="confirm" component={ScreeningConfirmationStep} />
            <Route path="review" component={ScreeningContactReview} />
            <Route path="search" component={ScreeningSearchContactStep} />
            <Route path="final-review" component={ScreeningFinalReview} />
          </Route>
        </Route>

        <Route path="reports/group" component={GroupReports} title="Group Report Dashboard">
          <Route path=":id/population" component={PopulationReport} />
          <Route path=":id/services" component={ServicesReport} />
          <Route path=":id/military" component={MilitaryReport} />
        </Route>

        <Route path="message-center" component={MessageCenter} title="Message Center" />
        <Route path="message-center/:conversationId" component={MessageCenter} title="Message Center" />
        <Route path="exports" component={Exports} title="Exports" />

        <Route path="insights" component={Insights} title="Insights" />

        <Route path="tasks" component={Tasks} title="Tasks" />

        <Route path="backoffice" component={BackOffice} title="Back Office">

          <Route
            path="fee_schedules" component={FeeSchedules} title="Fee Schedules"
            label="Fee Schedules"
          />
          <Route path="fee_schedules/new" component={CreateFeeSchedule} title="Add Fee Schedule" />
          <Route path="fee_schedules/:id/edit" component={EditFeeSchedule} title="Edit Fee Schedule" />
          <Route path="fee_schedules/:id" component={FeeSchedule}>
            <IndexRoute title="Fee Schedule Programs" />
            <Route path="plans" title="Fee Schedule Plans" />
            <Route path="fee-schedule-screenings" title="Screenings" />
            <Route path="invoice-rejection-reasons" title="Invoice Rejection Reasons" />
            <Route path="authorization-rejection-reasons" title="Authorization Rejection Reasons" />
            <Route path="care-managers" title="Care Managers" />
          </Route>
          <Route path="fee_schedules/:fee_schedule_id/fee-schedule-screenings/:fee_schedule_screening_id" component={FeeScheduleScreening}>
            <IndexRoute title="Overview" />
            <Route path="cbo-table" title="CBOs" />
            <Route path="edit" title="Edit Fee Schedule Screening" />
            <Route path="programs" title="Fee Schedule Screening Programs" />
          </Route>
          <Route path="fee_schedules/:fee_schedule_id/fee_schedule_screenings/new" component={FeeScheduleScreeningNew} title="Add Fee Schedule Screening" />
          <Route path="fee_schedules/:fee_schedule_id/plans/:plan_id" component={Plan}>
            <IndexRoute title="Overview" />
            <Route path="attachments" title="Attachments" />
          </Route>
          <Route path="fee_schedules/:fee_schedule_id/plans/:plan_id/edit" component={EditPlan} title="Edit Plan" />
          <Route path="fee_schedules/:id/fee_schedule_programs/new" component={CreateFeeScheduleProgram} title="Add Fee Schedule Program" />
          <Route path="fee_schedules/:id/fee_schedule_programs/:fee_schedule_program_id/edit" component={EditFeeScheduleProgram} title="Fee Schedule Program" />
          <Route path="fee_schedules/:id/fee_schedule_programs/:fee_schedule_program_id" component={FeeScheduleProgram}>
            <IndexRoute title="Overview" />
            <Route path="cbo-table" title="CBO Table" />
            <Route path="custom-fields" title="Custom Field" />
          </Route>
          {/* Payers */}
          <Route path="payers" label="Payers" component={Payers} />
          <Route path="payers/new" component={PayerForm} title="Add Fee Payer" />
          <Route path="payers/:id" component={Payer} title="View Payer" />
          <Route path="payers/:id/edit" component={PayerForm} title="Edit Payer" />
          <Route path="payers/:id/plans/new" component={CreatePlan} title="Add Plan" />
          <Route path="payers/:payer_id/plans/:plan_id/edit" component={EditPlan} title="Edit Plan" />
          <Route path="payers/:payer_id/plans/:plan_id" component={Plan}>
            <IndexRoute title="Overview" />
            <Route path="attachments" title="Attachments" />
          </Route>

          {/* Forms */}
          <Route path="forms" label="Forms" component={Forms} />
          <Route path="forms/new" component={FormNew} title="Add Form" />
          <Route path="forms/:id/edit" component={FormEdit} title="Edit Form" />
          <Route
            path="forms/:id/edit/:start_tab"
            component={FormEdit}
            title="Edit Form"
          />

          {/* Revoke Consent */}
          <Route path="revoke_consent" label="Revoke Consent" component={RevokeConsent} />

          {/* PRD */}
          <Route path="prds" label="PRDs" component={PrdTable} />
          <Route path="prds/new" component={CreatePrd} title="Create PRD" />
          <Route path="prds/:id" component={PrdOverview} title="View PRD" />
          <Route path="prds/:id/directory_configurations/:directory_configuration_id/edit" component={EditDirectoryConfiguration} title="Edit PRD details" />
          <Route path="prds/:id/edit" component={EditPrd} title="Edit PRD" />
        </Route>

        {/* eslint-disable react/jsx-max-props-per-line */}
        <Route path="invoices" component={InvoicesContainer} title="Invoices">
          <IndexRedirect to="/invoices/all" />
          <Route path="draft" component={DraftInvoices} title="Draft Invoices">
            <Route path=":id" component={InvoiceDetailPage} title="View Invoice Details" />
          </Route>
          <Route path="rejected" component={RejectedInvoicesDWQ} title="Rejected Invoices">
            <Route path=":id" component={InvoiceDetailPage} title="View Invoice Details" />
          </Route>
          <Route path="disputed" component={DisputedInvoices} title="Disputed Invoices">
            <Route path=":id" component={InvoiceDetailPage} title="View Invoice Details" />
          </Route>
          <Route path="submitted" component={SubmittedInvoicesDWQ} title="Submitted Invoices">
            <Route path=":id" component={InvoiceDetailPage} title="View Invoice Details" />
          </Route>
          <Route path="accepted" component={AcceptedInvoicesDWQ} title="Accepted Invoices">
            <Route path=":id" component={InvoiceDetailPage} title="View Invoice Details" />
          </Route>
          <Route path="open" component={OpenInvoices} title="Open Invoices">
            <Route path=":id" component={InvoiceDetailPage} title="View Invoice Details" />
          </Route>
          <Route path="closed" component={ClosedInvoices} title="Closed Invoices">
            <Route path=":id" component={InvoiceDetailPage} title="View Invoice Details" />
          </Route>
          <Route path="all" component={AllInvoices} title="All Invoices">
            <Route path=":id" component={InvoiceDetailPage} title="View Invoice Details" />
          </Route>
          <Route path="archive" component={ArchivedInvoicesDWQ} title="Archived Invoices">
            <Route path=":id" component={InvoiceDetailPage} title="View Invoice Details" />
          </Route>
          <Route path=":id" component={InvoiceDetailPage} title="View Invoice" />
        </Route>
        {/* eslint-enable react/jsx-max-props-per-line */}

        <Route path="authorizations" component={ServiceAuthorizationsContainer} title="Authorizations">
          <IndexRedirect to="/authorizations/all" />
          <Route
            path="all" component={AllServiceAuthorizations} label="All Authorizations"
            title="All Authorizations"
          />
          <Route
            path="open" component={OpenServiceAuthorizations} label="Open Authorizations"
            title="Open Authorizations"
          />
          <Route
            path="accepted" component={AcceptedServiceAuthorizations} label="Accepted Authorizations"
            title="Accepted Authorizations"
          />
          <Route
            path="rejected" component={RejectedServiceAuthorizations} label="Rejected Authorizations"
            title="Rejected Authorizations"
          />
          <Route path=":id" component={ServiceAuthorizationDetails} title="Authorization Details" />
          <Route path=":id/person/:person_id/forms/:assessment_id/submissions/:submission_id" component={ServiceAuthorizationAssessmentDetails} />
        </Route>

        <Route path="enrollments" component={EnrollmentsContainer} title="Enrollments">
          <IndexRedirect to="/enrollments/all" />
          <Route
            path="open" component={OpenEnrollments} label="Open Enrollments"
            title="Open Enrollments"
          />
          <Route
            path="accepted" component={AcceptedEnrollments} label="Accepted Enrollments"
            title="Accepted Enrollments"
          />
          <Route
            path="rejected" component={RejectedEnrollments} label="Rejected Enrollments"
            title="Rejected Enrollments"
          />
          <Route
            path="canceled" component={CanceledEnrollments} label="Canceled Enrollments"
            title="Canceled Enrollments"
          />
          <Route
            path="all" component={AllEnrollments} label="All Enrollments"
            title="All Enrollments"
          />
          <Route path=":id" component={EnrollmentDetailPage} title="View Enrollment Request" />
          <Route path=":id/person/:person_id/forms/:assessment_id/submissions/:submission_id" component={EnrollmentAssessmentDetailsView} />
        </Route>

        <Route
          path="organization/settings"
          component={OrganizationContainerLazyLoader}
          title="Organization Settings"
          uuOwnedBy={UU_OWNERS.resourcesExternal}
        >
          <IndexRoute component={Organization} />
          <Route path="edit" component={OrganizationEdit} title="Edit Organization" />
          <Route path="locations/new" component={OrganizationLocationNew} title="Add Location" />
          <Route path="locations/:location_id/edit" component={OrganizationLocationEdit} title="Edit Location" />
          <Route path="programs/new" component={OrganizationProgramNew} title="Add Program" />
          <Route path="programs/:program_id/edit" component={OrganizationProgramEdit} title="Edit Program" />
          <Route path="users" component={OrganizationUsers} title="Organization Users" />
          <Route path="users/new" component={OrganizationUserNew} title="Add User" />
          <Route path="users/:user_id" component={OrganizationUserDetail} title="User Detail" />
          <Route path="users/:user_id/edit" component={OrganizationUserEdit} title="Edit User" />
        </Route>

        <Redirect from="*" to={catchAllLoggedInRedirectPath} />
      </Route>
    </Route>
  );
}
