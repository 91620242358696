import _ from 'lodash';

function flagSelector({ key }) {
  const flag = _.camelCase(key);

  return (state) => state.flags[flag];
}

function roleFlagSelector({ parent, key }) {
  const feature = [_.snakeCase(parent), _.snakeCase(key)].join('_');

  return (state) => {
    const group = _.find(state.user.groups, {
      group: { id: state.session.groupId },
    });
    if (!group) return false;

    return _.get(group.feature_flags, feature, false);
  };
}

function roleSelector({ role_key }) {
  return (state) => {
    const group = _.find(state.user.groups, {
      group: { id: state.session.groupId },
    });
    if (!group) return false;

    return Boolean(_.find(group.roles, (r) => r.role_key === role_key));
  };
}

export const cerb1486Ny1115ClientSocialCareCoverageIds = flagSelector({
  key: 'cerb-1486-ny-1115-client-social-care-coverage-ids',
});

export const cerb1485Ny1115Networks = flagSelector({
  key: 'cerb-1485-ny-1115-networks',
});

export const cerbNy1115 = flagSelector({
  key: 'cerb-ny-1115',
});
export const cerb1519ScreeningRequiredFeeSchedules = flagSelector({
  key: 'cerb-1519-screening-required-fee-schedules',
});
export const cerb1455HasSuggestEdit = flagSelector({
  key: 'cerb-1455-has-suggest-edit',
});
export const cerb1367ResourceListDetailEnhancements = flagSelector({
  key: 'cerb-1367-resource-list-detail-enhancements',
});
export const cerb1218ParentStFilter = flagSelector({
  key: 'cerb-1218-parent-st-filter',
});
export const crtb1299HighRejectionSolution = flagSelector({
  key: 'crtb-1299-high-rejection-solution',
});
export const pays6694RemoveContextPerson = flagSelector({
  key: 'pays-6694-remove-context-person',
});
export const pays5590RefactorInsuranceFilters = flagSelector({
  key: 'pays-5590-refactor-insurance-filters',
});
export const crtb1290SortReferralsByOldest = flagSelector({
  key: 'crtb-1290-sort-referrals-by-oldest',
});
export const crtb219ChangeServiceTypeOnReferral = flagSelector({
  key: 'crtb-219-change-service-type-on-referrals',
});
export const hint1246HideLgbtqPlusFilter = flagSelector({
  key: 'hint-1246-hide-lgbtq-plus-filter',
});
export const crtb676CloseInboundReferrals = flagSelector({
  key: 'crtb-676-close-inbound-referrals',
});
export const crtb178ImproveDashboardFilters = flagSelector({
  key: 'crtb-178-improve-dashboard-filters',
});
export const crtb107ValidateDraftAssessments = flagSelector({
  key: 'crtb-107-required-form-fields-draft-referral',
});
export const uup459SupersetPhase2 = flagSelector({
  key: 'uup-459-superset-phase-2',
});
export const uup459SupersetRelease3 = flagSelector({
  key: 'uup-459-superset-release-3',
});
export const hint542SupersetUnlistedPrograms = flagSelector({
  key: 'hint-542-superset-unlisted-programs',
});
export const programBasedSearchSelector = flagSelector({
  key: 'hint-13-program-based-search',
});
export const hint716SearchNetworkHubSupportPremiumSelector = flagSelector({
  key: 'hint-716-search-network-hub-support-premium',
});
export const useInvoiceDisputeWorkflow = flagSelector({
  key: 'useInvoiceDisputeWorkflow',
});
export const organizationPaginationLimit = flagSelector({
  key: 'organization-pagination-limit',
});
export const hasInAppNotifications = flagSelector({
  key: 'notifications-user',
});
export const hasInsightsUserRole = flagSelector({ key: 'insights-user-role' });
export const hasExportsUserRole = flagSelector({ key: 'exports-user-role' });
export const hasNetworkDirectoryUserRole = flagSelector({
  key: 'network-directory-user-role',
});
export const hasGeneralStaffUserRole = flagSelector({ key: 'staff-user-role' });
export const hasReportsUserRole = flagSelector({ key: 'reports-user-role' });
export const showAuthorizationsFilterbar = flagSelector({
  key: 'pays-3535-service-authorization-wq-filterbar',
});
export const hasAmountProvided = flagSelector({
  key: 'pays-4037-contracted-service-amount-provided',
});
export const hasPaymentsNetworksServicesUserRole = flagSelector({
  key: 'payments-network-services',
});
export const hasCoreConsolidation = flagSelector({ key: 'core-consolidation' });
export const hasNCCorrectPaymentWording = flagSelector({
  key: 'nc-correct-payment-wording',
});
export const usePhiDisclosure = flagSelector({
  key: 'uud-49-use-phi-disclosure',
});
export const hasCasesOONReferralUnlicensedOrgs = flagSelector({
  key: 'cases-oon-referral-unlicensed-orgs',
});
export const showProgramStatusToggle = flagSelector({
  key: 'show-program-status-toggle',
});
export const showPendo = flagSelector({ key: 'uucs-315-pendo-temp' });
export const hasNewSearchAndMatch = flagSelector({
  key: 'new-search-and-match',
});
export const useV4toGetOrganizations = flagSelector({
  key: 'uu3-47928-use-v4-open-cases-org-dropdown-temp',
});
export const hideResidencyRequirementsFilter = flagSelector({
  key: 'hide-residency-requirements-filter',
});
export const paginateNetworkGroups = flagSelector({
  key: 'paginate-network-groups',
});
export const expandAllReferralReviewSections = flagSelector({
  key: 'uu3-51060-expand-all-referral-review-sections-temp',
});
export const isMessagingEnabledFlag = flagSelector({
  key: 'uup-235-messaging-center-access',
});
export const isMessagingArchiveEnabledFlag = flagSelector({
  key: 'archive-messaging',
});
export const restrictToSubmissionContext = flagSelector({
  key: 'cpr-369-restrict-to-submission-context',
});
export const showTranslationsNewLanguages = flagSelector({
  key: 'translations-new-language',
});
export const serviceAreaSupportForOrgs = flagSelector({
  key: 'UUP-245-service-area-support-for-orgs',
});
export const hasInvoicesAccess = roleSelector({ role_key: 'invoices' });
export const hasReferralsUser = roleSelector({ role_key: 'referrals_user' });
export const hasPaysClaimsCodes = flagSelector({
  key: 'pays-claims-codes-dropdowns',
});

export const hasPayerInvoicesRole = roleSelector({
  role_key: 'payer_invoices',
});
export const hasPaymentsUserAccess = roleSelector({ role_key: 'payments' });

export const hasPayerAuthorizationAccess = roleSelector({
  role_key: 'service_authorizations',
});
export const hasCaseManagerRole = roleSelector({ role_key: 'case_manager' });
export const hasEnrollmentsWQ = flagSelector({ key: 'enrollment-wq' });
export const pays3551AuthInSuperset = flagSelector({
  key: 'pays-3551-auth-in-superset',
});
export const crtb1127AuthPaymentProgramsInReferrals = flagSelector({
  key: 'crtb-1127-auth-payment-programs-in-referrals',
});
export const crtb1239AdaptDraftReferralFlow = flagSelector({
  key: 'crtb-1239-adapt-draft-referral-flow',
});
export const crtb1349VariableAWebApp = flagSelector({
  key: 'crtb-1349-variable-a-webb-app',
});
export const crtb1350VariableBWebApp = flagSelector({
  key: 'crtb-1350-variable-b-web-app',
});
export const crtb1351VariableCWebApp = flagSelector({
  key: 'crtb-1351-variable-c-web-app',
});
export const crtb1443ShowARFacesheet = flagSelector({
  key: 'crtb-1443-show-AR-facesheet',
});
export const crtb824AddOpenCloseDateOffPlatformCase = flagSelector({
  key: 'crtb-824-add-open-close-date-off-platform-case',
});
export const crtb1234ShowProgramNameReferralHistory = flagSelector({
  key: 'crtb-1234-show-program-name-referral-history',
});
export const crtb1060ChangeReasonInReviewReferral = flagSelector({
  key: 'crtb-1060-change-hold-reason',
});
export const crtb854ForwardingSuperset = flagSelector({
  key: 'crtb-854-forwarding-superset',
});
export const crtb1285DisplayProgramEligReferralWorkflow = flagSelector({
  key: 'crtb-1285-display-program-elig-referral-workflow',
});

export const hasPayerEnrollmentsAccess = (state) => {
  const hasEnrollments = roleSelector({ role_key: 'enrollments' })(state);
  return hasEnrollments && hasEnrollmentsWQ(state);
};

export const hasExports = (state) => roleFlagSelector({ parent: 'exports', key: 'exports_data' })(state);

export const hasInsightsViewInsights = (state) => roleFlagSelector({ parent: 'insights', key: 'view_insights' })(state);

const navRoleFlagSelector = ({ parent, key }) => {
  const hasRole = roleFlagSelector({ parent, key });
  return (state) => hasRole(state);
};

export const hasNetworkDirectoryAccess = navRoleFlagSelector({
  parent: 'network_directory',
  key: 'view_directory_emr',
});
export const hasContactsViewContacts = navRoleFlagSelector({
  parent: 'contacts',
  key: 'create_contacts',
});
export const hasContactsCreateContacts = navRoleFlagSelector({
  parent: 'contacts',
  key: 'create_contacts',
});
export const hasWorkflowViewIndex = navRoleFlagSelector({
  parent: 'workflow_index',
  key: 'view_index',
});
export const hasReportsViewReports = navRoleFlagSelector({
  parent: 'reports',
  key: 'view_reports',
});
export const hasTableauDashboard = flagSelector({
  key: 'uup-20-use-tableau-dashboard',
});
export const showNCDisplay = flagSelector({ key: 'nc-display-only' });
export const showUpdatedContactRequirements = flagSelector({
  key: 'crtb-50-show-contact-preferences',
});

export const showFacesheetCasesTableDescription = flagSelector({
  key: 'facesheet-cases-table-description',
});

export const includePathwaysServices = flagSelector({
  key: 'service-types-include-pathways',
});
export const forbidContractedServiceCreationForUnconsentedClient = flagSelector(
  {
    key: 'pays-3020-forbid-creating-contracted-service-for-unconsented-client',
  },
);

export const showResourceLists = flagSelector({ key: 'show-resource-lists' });
export const editResourceList = flagSelector({ key: 'edit-resource-lists' });

export const requireSCCStartDate = flagSelector({
  key: 'pays-2525-temp-scc-start-at',
});
export const showNCTracks = flagSelector({ key: 'pays-1554-nctracks' });
export const showSCC = flagSelector({ key: 'PAYS_4502_show_scc' });
export const showAppClientProfile = flagSelector({ key: 'show-app-client-profile' });
export const pays5525ShowCurrentInsuranceCoverage = flagSelector({ key: 'pays-5525-show-current-insurance-coverage' });

export const hasDeleteNoteIcon = flagSelector({
  key: 'tool-158-delete-note-icon',
});

export const isLearnEnabled = flagSelector({ key: 'unite-us-learn-link' });

export const orgAdminProgramStatusNA = flagSelector({
  key: 'cerb-668-program-status-temporarily-not-available',
});
export const orgAdminDeactivatedPrograms = flagSelector({
  key: 'cerb-722-deactivated-programs-for-org-admins',
});
export const isTasksMenuEnabled = flagSelector({ key: 'tasks-menu' });
export const isTasksListV2Enabled = flagSelector({ key: 'nc-task-list-v2' });

export const hasPaymentsTrackServiceRefactoring = flagSelector({
  key: 'pays-3318-payments-track-service-refactoring',
});

export const hideMoreFiltersOption = flagSelector({
  key: 'crtb-916-improve-dashboard-filters',
});
export const hasNewReasonFilter = flagSelector({
  key: 'crtb-224-new-reason-filter-for-referrals',
});

export const hint921ManualSendback = flagSelector({
  key: 'hint-921-manual-sendback',
});
export const hint1066PaymentsLiteModal = flagSelector({
  key: 'hint-1066-payments-lite-modal',
});

export const hasLimitedGenderOptions = flagSelector({
  key: 'crtb-1120-hide-client-profile-gender-options',
});
export const crtb1121HideFieldInForm = flagSelector({
  key: 'crtb-1121-hide-field-in-form',
});
export const hasClientLinkOnClientFacesheet = flagSelector({
  key: 'crtb-1152-add-client-id-to-the-client-face-sheet',
});
export const hasReferralNoteSection = flagSelector({
  key: 'crtb-1172-add-referral-note-section-to-case-ui',
});
export const hbh1025ShowEligibilityAssessmentTab = flagSelector({
  key: 'hbh-1025-show-eligibility-assessment-tab',
});
export const cerb1199IsSupersetMyNetwork = flagSelector({
  key: 'CERB-1199-Is-Superset-My-Network',
});

export const hasNewRejectionReason = flagSelector({ key: 'crtb-1154-new-rejection-reason' });
export const pays5604AuthorizationForInternalCases = flagSelector({
  key: 'pays-5604-authorization-for-internal-cases',
});
export const scrn7ScreeningsCompletion = flagSelector({ key: 'scrn7-screenings-completion' });
export const scrn468ScreeningTimeTracking = flagSelector({ key: 'scrn-468-screening-time-tracking' });
export const scrn655inboundScreeningsDashboard = flagSelector({ key: 'scrn-655-inbound-screenings-dashboard' });

export const pays3513PreventOverlappingRequests = flagSelector({
  key: 'pays-3513-prevent-overlapping-scc-and-enrollment-requests',
});

export const isClientMergingEnabled = flagSelector({ key: 'cl-67-client-merging' });
export const hasClientIdStandardization = flagSelector({ key: 'cl-657-id-standardization' });
export const cl787SexualityFields = flagSelector({ key: 'cl-787-sexuality-fields' });
export const cl1018PreferredLanguagesFields = flagSelector({ key: 'cl-1018-preferred-language' });
export const assistanceRequestsExportsAvailable = flagSelector({
  key: 'hbh-1022-in-app-exports',
});
export const cl1292clientsIndexMaterializedView = flagSelector({
  key: 'cl-1292-clients-index-materialized-view',
});

export const hint1980ShowSharesMessageBox = flagSelector({
  key: 'hint-1980-show-shares-message-box',
});

export const cl1224UnconsentedClientFacesheetAccess = flagSelector({
  key: 'cl-1224-unconsented-client-face-sheet-access',
});

export const crtb1346AddANoteDateChange = flagSelector({
  key: 'crtb-1346-add-a-note-date-change',
});

export const pays7267IncludeInternalNonConsentedClients = flagSelector({
  key: 'PAYS-7267-include-internal-nonconsent',
});

export const crtb1645ARDateRangeFilter = flagSelector({
  key: 'crtb-1645-AR-date-range-filter',
});
